
import '!file-loader?name=android-chrome-192x192.png!./android-chrome-192x192.png';
import '!file-loader?name=android-chrome-512x512.png!./android-chrome-512x512.png';
import '!file-loader?name=apple-touch-icon.png!./apple-touch-icon.png';
import '!file-loader?name=browserconfig.xml!./browserconfig.xml';
import '!file-loader?name=favicon.ico!./favicon.ico';
import '!file-loader?name=favicon-16x16.png!./favicon-16x16.png';
import '!file-loader?name=favicon-32x32.png!./favicon-32x32.png';
import '!file-loader?name=mstile-70x70.png!./mstile-70x70.png';
import '!file-loader?name=mstile-144x144.png!./mstile-144x144.png';
import '!file-loader?name=mstile-150x150.png!./mstile-150x150.png';
import '!file-loader?name=mstile-310x150.png!./mstile-310x150.png';
import '!file-loader?name=mstile-310x310.png!./mstile-310x310.png';
import '!file-loader?name=safari-pinned-tab.svg!./safari-pinned-tab.svg';
import '!file-loader?name=site.webmanifest!./site.webmanifest';
